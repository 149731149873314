<template>
  <div class="login-container">
    <el-form
      :model="ruleForm2"
      :rules="rules2"
      status-icon
      ref="ruleForm2"
      label-position="left"
      label-width="80px"
      class="demo-ruleForm login-page"
    >
      <h3 class="title">注册</h3>
      <el-form-item
        prop="name"
        label="姓名"
      >
        <el-input
          type="text"
          v-model="ruleForm2.name"
          auto-complete="off"
          placeholder="姓名"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="username"
        label="账号"
      >
        <el-input
          type="text"
          v-model="ruleForm2.username"
          auto-complete="off"
          placeholder="账号"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="password"
        label="密码"
      >
        <el-input
          type="password"
          v-model="ruleForm2.password"
          auto-complete="off"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="mobile"
        label="手机"
      >
        <el-input
          type="text"
          v-model="ruleForm2.mobile"
          auto-complete="off"
          placeholder="手机"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="email"
        label="邮箱"
      >
        <el-input
          type="text"
          v-model="ruleForm2.email"
          auto-complete="off"
          placeholder="邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="role"
        label="角色"
      >
        <el-select
          v-model="ruleForm2.role"
          placeholder="请选择"
          style="width:270px;"
        >
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div>
        <el-button
          type="primary"
          style="width:100%;"
          @click="handleSubmit"
          :loading="logining"
        >提交</el-button>
      </div>
      <br />
      <el-button
        style="margin-right:80px;"
        @click="clearClick"
      >清空</el-button>
      <el-button
        style="margin-left:80px;"
        @click="toLogin"
      >登录</el-button>
    </el-form>
  </div>
</template>

<script>
import cryptoJs from "crypto-js";
export default {
  data() {
    return {
      from: "",
      key: "rest@126.com",
      logining: false,
      ruleForm2: {
        name: "",
        username: "",
        password: "",
        email: "",
        mobile: "",
        role: "",
      },
      roleList: [
        {
          value: "1",
          label: "学生",
        },
        {
          value: "2",
          label: "教师",
        },
      ],
      rules2: {
        email: [
          {
            required: true,
            message: "please enter your email",
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: "enter your mobile", trigger: "blur" },
        ],
        name: [{ required: true, message: "enter your name", trigger: "blur" }],
        username: [
          { required: true, message: "enter your username", trigger: "blur" },
        ],
        password: [
          { required: true, message: "enter your password", trigger: "blur" },
        ],
        role: [
          { required: true, message: "select your role", trigger: "blur" },
        ],
      },
      checked: false,
    };
  },
  created() {
    this.from = this.$route.query.from;
  },
  methods: {
    async emailClick() {
      if (
        this.ruleForm2.email === "" ||
        this.ruleForm2.email === null ||
        this.ruleForm2.email === undefined
      ) {
        this.$message.error("请输入邮箱");
        return;
      }
      let param = {
        emailTo: this.ruleForm2.email.trim(),
      };
      const result = await this.$http.post("/library/user/email", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.$message.success("邮件发送成功！");
    },
    async handleSubmit() {
      let param = {
        username: this.ruleForm2.username,
        name: this.ruleForm2.name,
        password: this.encryptDes(this.ruleForm2.password),
        email: this.ruleForm2.email,
        mobile: this.ruleForm2.mobile,
        role: this.ruleForm2.role,
      };
      const result = await this.$http.post("/rest/user/register", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.$router.push({ path: "/login", query: { from: this.from } });
    },
    toLogin() {
      this.$router.push({ path: "/login", query: { from: this.from } });
    },
    encryptDes(message) {
      const keyHex = cryptoJs.enc.Utf8.parse(this.key);
      const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
      const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
      return encrypted.toString();
    },
    clearClick() {
      this.ruleForm2 = {};
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: auto;
  z-index: 0;
  zoom: 1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/opera/0eb97fc3ae1a8205f02cff8d2f06bab1.jpeg);
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  margin-left:400px;
  /* margin-left: 180px;
  margin-right: 180px;
  margin-top: 120px;
  margin-bottom: 120px; */
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
